import React, { useState } from "react";
import styles from "./Market.module.sass";
// import cn from "classnames";
import Checkbox from "../../../../components/Checkbox";
import Icon from "../../../../components/Icon";
import Row from "./Row";

const Market = ({ items }) => {
  // const [chooseAll, setСhooseAll] = useState(false);

  // const [selectedFilters, setSelectedFilters] = useState([]);

  // const handleChange = (id) => {
  //   if (selectedFilters.includes(id)) {
  //     setSelectedFilters(selectedFilters.filter((x) => x !== id));
  //   } else {
  //     setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
  //   }
  // };

  return (
    <div className={styles.market}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>
            {/* <Checkbox
              className={styles.checkbox}
              value={chooseAll}
              onChange={() => setСhooseAll(!chooseAll)}
            /> */}
          Property
          </div>
          <div className={styles.col}>DOM</div>
          <div className={styles.col}>List Price</div>
          <div className={styles.col}>Suggested Offer Price</div>
          <div className={styles.col}>ARV</div>
          <div className={styles.col}>Est.Rehab</div>
          <div className={styles.col}>Delta</div>
          <div className={styles.col}>Distress Ranking</div>
          <div className={styles.col}>Listing Description</div>
          <div className={styles.col}>Private Remarks</div>
        
        </div>
        {items.map((x,index) => (
  
          <Row
            item={x}
            key={x.mls_id}
            up={items.length - index <= 2}
            // value={selectedFilters.includes(x.price)}
            // onChange={() => handleChange(x.mls_id)}
          />
        ))}
      </div>
      {/* <div className={styles.foot}>
        <button className={styles.arrow}>
          <Icon name="arrow-left" size="20" />
        </button>
        <button className={styles.arrow}>
          <Icon name="arrow-right" size="20" />
        </button>
      </div> */}
    </div>
  );
};

export default Market;
