import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import Checkbox from "../../../../../components/Checkbox";
import Balance from "../../../../../components/Balance";
import ModalProduct from "../../../../../components/ModalProduct";
import Control from "../../Control";

import { numberWithCommas } from "../../../../../utils.js";

const Row = ({ item, onChange, up }) => {
  const [visibleActions, setVisibleActions] = useState(false);
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);
  const imageUrl = "/images/content/booking1.png";
  return (
    <>
      <div
        className={styles.row}
        onMouseLeave={() => setVisibleActions(!visibleActions)}
      >
        <div className={styles.col}>
          <div
            className={styles.item}
            onClick={() => setVisibleModalProduct(!visibleModalProduct)}
          >
            <div className={styles.preview}>
              <img
                srcSet={`${item.image2x} 2x`}
                src={imageUrl}
                alt="Property"
              />
            </div>
            <div className={styles.details}>
              <div className={styles.address}>
                {item.address_number + " "}
                {item.address_street}
              </div>
              <div className={styles.address}>
                {item.city}, {item.state}, {item.zip}
              </div>
              <div className={styles.wrapProperty}>
                <div className={styles.category}>
                  <span style={{ paddingRight: "4px" }}>
                    {item.bedrooms + " Bed "}
                  </span>
                  <span style={{ paddingRight: "4px" }}>
                    {item.bathrooms + " Bath "}
                  </span>
                  <span>{item.square_feet + " sqft"}</span>{" "}
                </div>
              </div>
              <div className={styles.wrapProperty}>
                <div className={styles.category}>
                  {item.agent_first_name + " " + item.agent_last_name || ""}{" "}
                </div>
                <div className={styles.category}>{item.agent_phone || ""} </div>
                <div className={styles.category}>
                  {item.contactEmail || ""}{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.dom}>{item.unit_number || "NA"}</div>
        </div>
        <div className={styles.col}><span>{item.price && '$'}</span>{item.price || "NA"}</div>
        <div className={styles.col}>
          <div className={styles.sales}>
            <div className={styles.number}>
              <span>{item.msa && '$'}</span>
              {numberWithCommas(item.msa || "NA")}
            </div>
          </div>
        </div>
        <div className={styles.col}>
        <span>{item.final_avm_oc_after_repair_value && '$'}</span>
          {numberWithCommas(item.final_avm_oc_after_repair_value || "NA")}
        </div>
        <div className={styles.col}>
        <span>{item.total_full_rehab && '$'}</span>
          {numberWithCommas(item.total_full_rehab || "NA")}
        </div>
        <div className={styles.col}>
          {item.total_positive_score !== null &&
            item.total_positive_score !== undefined && (
              <Balance
                className={styles.balance}
                value={item.total_positive_score}
              />
            )}
          {item.total_negative_score !== null &&
            item.total_negative_score !== undefined && (
              <Balance
                className={styles.balance}
                value={-item.total_negative_score}
              />
            )}
        </div>
        <div className={styles.col}>
          <div className={styles.drank}>{item.total_home_distress || "NA"}</div>
        </div>
        <div className={styles.col}>
          <div className={styles.wrap}>
            <div className={styles.category}>
              {item.property_description || ""}
            </div>
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.wrap}>
            <div className={styles.category}>{item.remark || "NA"}</div>
          </div>
        </div>
      </div>
      <ModalProduct
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
    </>
  );
};

export default Row;
