import { progress } from "../utils";

export const market = [
  {
    id: 0,
    address:"8658 Laural Canyon Blvd",
    city:"Sun Valley",
    state:"CA",
    pincode:"91352",
    bed:"2",
    bath:"2",
    area:"900",
    contactPerson:"Jessica Nieto",
    phoneNumber:"562-714-2156",
    contactEmail:"Jessica@booknieto.com",
    dom: 2,
    listPrice: 98,
    suggestedPrice: 3200,
    delta: 37.8,
    rehab: 79850,
    viewsPercent: progress(),
    likesPercent: progress(),
    arv: 2111,
    distressRanking:8,
    proper: "Bento Matte 3D Illustration",
    category: "UI design kit",
    image: "/images/content/booking1.png",
    image2x: "/images/content/product-pic-1@2x.jpg",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce maximus quam non neque efficitur tincidunt. Nullam sit amet malesuada dolor. Mauris vestibulum vehicula orci, ac feugiat risus pellentesque dapibus. Vestibulum ligula lorem, tincidunt at nisi sed, tincidunt vestibulum ex. Donec scelerisque justo sed nulla rhoncus convallis. Nunc elit neque, hendrerit sed urna eu, dictum suscipit ipsum. Vivamus non gravida risus, vitae congue velit. Vivamus congue blandit porttitor. Ut imperdiet, metus eget elementum tincidunt, felis lacus ullamcorper eros, non efficitur enim odio et orci. Donec rutrum magna non mauris feugiat feugiat. Suspendisse potenti. Maecenas sodales porttitor massa, at rhoncus urna consequat.",
    remark:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce maximus quam non neque efficitur tincidunt. Nullam sit amet malesuada dolor. Mauris vestibulum vehicula orci, ac feugiat risus pellentesque dapibus. Vestibulum ligula lorem, tincidunt at nisi sed, tincidunt vestibulum ex. Donec scelerisque justo sed nulla rhoncus convallis. Nunc elit neque, hendrerit sed urna eu, dictum suscipit ipsum. Vivamus non gravida risus, vitae congue velit. Vivamus congue blandit porttitor. Ut imperdiet, metus eget elementum tincidunt, felis lacus ullamcorper eros, non efficitur enim odio et orci. Donec rutrum magna non mauris feugiat feugiat. Suspendisse potenti. Maecenas sodales porttitor massa, at rhoncus urna consequat."
  },
  {
    id: 1,
    address:"8658 Laural Canyon Blvd",
    city:"Sun Valley",
    state:"CA",
    pincode:"91352",
    bed:"2",
    bath:"2",
    area:"900",
    contactPerson:"Jessica Nieto",
    phoneNumber:"562-714-2156",
    contactEmail:"jessica@booknieto.com",
    dom: 2,
    listPrice: 98,
    suggestedPrice: 3200,
    delta: 37.8,
    rehab: 79850,
    viewsPercent: progress(),
    likesPercent: progress(),
    arv: 2111,
    distressRanking:8,
    product: "DSM - Geometry pattern",
    category: "UI design kit",
    image: "/images/content/booking2.png",
    image2x: "/images/content/product-pic-2@2x.jpg",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce maximus quam non neque efficitur tincidunt. Nullam sit amet malesuada dolor. Mauris vestibulum vehicula orci, ac feugiat risus pellentesque dapibus. Vestibulum ligula lorem, tincidunt at nisi sed, tincidunt vestibulum ex. Donec scelerisque justo sed nulla rhoncus convallis. Nunc elit neque, hendrerit sed urna eu, dictum suscipit ipsum. Vivamus non gravida risus, vitae congue velit. Vivamus congue blandit porttitor.",
    remark: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce maximus quam non neque efficitur tincidunt. Nullam sit amet malesuada dolor. Mauris vestibulum vehicula orci, ac feugiat risus pellentesque dapibus. Vestibulum ligula lorem, tincidunt at nisi sed, tincidunt vestibulum ex. Donec scelerisque justo sed nulla rhoncus convallis. Nunc elit neque, hendrerit sed urna eu, dictum suscipit ipsum. Vivamus non gravida risus, vitae congue velit. Vivamus congue blandit porttitor."
  },
  {
    id: 2,
    address:"8658 Laural Canyon Blvd",
    city:"Sun Valley",
    state:"CA",
    pincode:"91352",
    bed:"2",
    bath:"2",
    area:"900",
    contactPerson:"Jessica Nieto",
    phoneNumber:"562-714-2156",
    contactEmail:"jessica@booknieto.com",
    dom: 2,
    listPrice: 98,
    suggestedPrice: 3200,
    delta: 37.8,
    rehab: 79850,
    viewsPercent: progress(),
    likesPercent: progress(),
    arv: 2111,
    distressRanking:8,
    product: "Node - Crypto iOS UI design kit",
    category: "UI design kit",
    image: "/images/content/booking3.png",
    image2x: "/images/content/product-pic-3@2x.jpg",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce maximus quam non neque efficitur tincidunt. Nullam sit amet malesuada dolor. Mauris vestibulum vehicula orci, ac feugiat risus pellentesque dapibus. Vestibulum ligula lorem, tincidunt at nisi sed, tincidunt vestibulum ex. Donec scelerisque justo sed nulla rhoncus convallis. Nunc elit neque, hendrerit sed urna eu, dictum suscipit ipsum. Vivamus non gravida risus, vitae congue velit. Vivamus congue blandit porttitor.",
    remark: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce maximus quam non neque efficitur tincidunt. Nullam sit amet malesuada dolor. Mauris vestibulum vehicula orci, ac feugiat risus pellentesque dapibus. Vestibulum ligula lorem, tincidunt at nisi sed, tincidunt vestibulum ex. Donec scelerisque justo sed nulla rhoncus convallis. Nunc elit neque, hendrerit sed urna eu, dictum suscipit ipsum. Vivamus non gravida risus, vitae congue velit. Vivamus congue blandit porttitor."
  },
  {
    id: 3,
    address:"8658 Laural Canyon Blvd",
    city:"Sun Valley",
    state:"CA",
    pincode:"91352",
    bed:"2",
    bath:"2",
    area:"900",
    contactPerson:"Jessica Nieto",
    phoneNumber:"562-714-2156",
    contactEmail:"jessica@booknieto.com",
    dom: 2,
    listPrice: 98,
    suggestedPrice: 3200,
    delta: 37.8,
    rehab: 79850,
    viewsPercent: progress(),
    likesPercent: progress(),
    arv: 2111,
    distressRanking:8,
    product: "TaskEz: Productivity App iOS UI Kit",
    category: "UI design kit",
    image: "/images/content/booking4.png",
    image2x: "/images/content/product-pic-4@2x.jpg",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce maximus quam non neque efficitur tincidunt. Nullam sit amet malesuada dolor. Mauris vestibulum vehicula orci, ac feugiat risus pellentesque dapibus. Vestibulum ligula lorem, tincidunt at nisi sed, tincidunt vestibulum ex. Donec scelerisque justo sed nulla rhoncus convallis. Nunc elit neque, hendrerit sed urna eu, dictum suscipit ipsum. Vivamus non gravida risus, vitae congue velit. Vivamus congue blandit porttitor.",
    remark: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce maximus quam non neque efficitur tincidunt. Nullam sit amet malesuada dolor. Mauris vestibulum vehicula orci, ac feugiat risus pellentesque dapibus. Vestibulum ligula lorem, tincidunt at nisi sed, tincidunt vestibulum ex. Donec scelerisque justo sed nulla rhoncus convallis. Nunc elit neque, hendrerit sed urna eu, dictum suscipit ipsum. Vivamus non gravida risus, vitae congue velit. Vivamus congue blandit porttitor."
  },
  {
    id: 4,
    address:"8658 Laural Canyon Blvd",
    city:"Sun Valley",
    state:"CA",
    pincode:"91352",
    bed:"2",
    bath:"2",
    area:"900",
    contactPerson:"Jessica Nieto",
    phoneNumber:"562-714-2156",
    contactEmail:"jessica@booknieto.com",
    dom: 2,
    listPrice: 98,
    suggestedPrice: 3200,
    delta: 37.8,
    rehab: 79850,
    viewsPercent: progress(),
    likesPercent: progress(),
    arv: 2111,
    distressRanking:8,
    product: "Academe 3D Education Icons",
    category: "UI design kit",
    image: "/images/content/product-pic-5.jpg",
    image2x: "/images/content/product-pic-5@2x.jpg",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce maximus quam non neque efficitur tincidunt. Nullam sit amet malesuada dolor. Mauris vestibulum vehicula orci, ac feugiat risus pellentesque dapibus. Vestibulum ligula lorem, tincidunt at nisi sed, tincidunt vestibulum ex. Donec scelerisque justo sed nulla rhoncus convallis. Nunc elit neque, hendrerit sed urna eu, dictum suscipit ipsum. Vivamus non gravida risus, vitae congue velit. Vivamus congue blandit porttitor.",
    remark: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce maximus quam non neque efficitur tincidunt. Nullam sit amet malesuada dolor. Mauris vestibulum vehicula orci, ac feugiat risus pellentesque dapibus. Vestibulum ligula lorem, tincidunt at nisi sed, tincidunt vestibulum ex. Donec scelerisque justo sed nulla rhoncus convallis. Nunc elit neque, hendrerit sed urna eu, dictum suscipit ipsum. Vivamus non gravida risus, vitae congue velit. Vivamus congue blandit porttitor."
  },
  {
    id: 5,
    address:"8658 Laural Canyon Blvd",
    city:"Sun Valley",
    state:"CA",
    pincode:"91352",
    bed:"2",
    bath:"2",
    area:"900",
    contactPerson:"Jessica Nieto",
    phoneNumber:"562-714-2156",
    contactEmail:"jessica@booknieto.com",
    dom: 2,
    listPrice: 98,
    suggestedPrice: 3200,
    delta: 37.8,
    rehab: 79850,
    viewsPercent: progress(),
    likesPercent: progress(),
    arv: 2111,
    distressRanking:8,
    product: "Bento Matte 3D Illustration",
    category: "UI design kit",
    image: "/images/content/product-pic-6.jpg",
    image2x: "/images/content/product-pic-6@2x.jpg",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce maximus quam non neque efficitur tincidunt. Nullam sit amet malesuada dolor. Mauris vestibulum vehicula orci, ac feugiat risus pellentesque dapibus. Vestibulum ligula lorem, tincidunt at nisi sed, tincidunt vestibulum ex. Donec scelerisque justo sed nulla rhoncus convallis. Nunc elit neque, hendrerit sed urna eu, dictum suscipit ipsum. Vivamus non gravida risus, vitae congue velit. Vivamus congue blandit porttitor.",
    remark: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce maximus quam non neque efficitur tincidunt. Nullam sit amet malesuada dolor. Mauris vestibulum vehicula orci, ac feugiat risus pellentesque dapibus. Vestibulum ligula lorem, tincidunt at nisi sed, tincidunt vestibulum ex. Donec scelerisque justo sed nulla rhoncus convallis. Nunc elit neque, hendrerit sed urna eu, dictum suscipit ipsum. Vivamus non gravida risus, vitae congue velit. Vivamus congue blandit porttitor."
  },
  {
    id: 6,
    address:"8658 Laural Canyon Blvd",
    city:"Sun Valley",
    state:"CA",
    pincode:"91352",
    bed:"2",
    bath:"2",
    area:"900",
    contactPerson:"Jessica Nieto",
    phoneNumber:"562-714-2156",
    contactEmail:"jessica@booknieto.com",
    dom: 2,
    listPrice: 98,
    suggestedPrice: 3200,
    delta: 37.8,
    rehab: 79850,
    viewsPercent: progress(),
    likesPercent: progress(),
    arv: 2111,
    distressRanking:8,
    product: "DSM - Geometry pattern",
    category: "UI design kit",
    image: "/images/content/booking1.png",
    image2x: "/images/content/product-pic-1@2x.jpg",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce maximus quam non neque efficitur tincidunt. Nullam sit amet malesuada dolor. Mauris vestibulum vehicula orci, ac feugiat risus pellentesque dapibus. Vestibulum ligula lorem, tincidunt at nisi sed, tincidunt vestibulum ex. Donec scelerisque justo sed nulla rhoncus convallis. Nunc elit neque, hendrerit sed urna eu, dictum suscipit ipsum. Vivamus non gravida risus, vitae congue velit. Vivamus congue blandit porttitor.",
    remark: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce maximus quam non neque efficitur tincidunt. Nullam sit amet malesuada dolor. Mauris vestibulum vehicula orci, ac feugiat risus pellentesque dapibus. Vestibulum ligula lorem, tincidunt at nisi sed, tincidunt vestibulum ex. Donec scelerisque justo sed nulla rhoncus convallis. Nunc elit neque, hendrerit sed urna eu, dictum suscipit ipsum. Vivamus non gravida risus, vitae congue velit. Vivamus congue blandit porttitor."
  },
  {
    id: 7,
    address:"8658 Laural Canyon Blvd",
    city:"Sun Valley",
    state:"CA",
    pincode:"91352",
    bed:"2",
    bath:"2",
    area:"900",
    contactPerson:"Jessica Nieto",
    phoneNumber:"562-714-2156",
    contactEmail:"jessica@booknieto.com",
    dom: 2,
    listPrice: 98,
    suggestedPrice: 3200,
    delta: 37.8,
    rehab: 79850,
    viewsPercent: progress(),
    likesPercent: progress(),
    arv: 2111,
    distressRanking:8,
    product: "Bento Matte 3D Illustration",
    category: "UI design kit",
    image: "/images/content/booking2.png",
    image2x: "/images/content/product-pic-2@2x.jpg",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce maximus quam non neque efficitur tincidunt. Nullam sit amet malesuada dolor. Mauris vestibulum vehicula orci, ac feugiat risus pellentesque dapibus. Vestibulum ligula lorem, tincidunt at nisi sed, tincidunt vestibulum ex. Donec scelerisque justo sed nulla rhoncus convallis. Nunc elit neque, hendrerit sed urna eu, dictum suscipit ipsum. Vivamus non gravida risus, vitae congue velit. Vivamus congue blandit porttitor.",
    remark: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce maximus quam non neque efficitur tincidunt. Nullam sit amet malesuada dolor. Mauris vestibulum vehicula orci, ac feugiat risus pellentesque dapibus. Vestibulum ligula lorem, tincidunt at nisi sed, tincidunt vestibulum ex. Donec scelerisque justo sed nulla rhoncus convallis. Nunc elit neque, hendrerit sed urna eu, dictum suscipit ipsum. Vivamus non gravida risus, vitae congue velit. Vivamus congue blandit porttitor."
  },
  {
    id: 8,
    address:"8658 Laural Canyon Blvd",
    city:"Sun Valley",
    state:"CA",
    pincode:"91352",
    bed:"2",
    bath:"2",
    area:"900",
    contactPerson:"Jessica Nieto",
    phoneNumber:"562-714-2156",
    contactEmail:"jessica@booknieto.com",
    dom: 2,
    listPrice: 98,
    suggestedPrice: 3200,
    delta: 37.8,
    rehab: 79850,
    viewsPercent: progress(),
    likesPercent: progress(),
    arv: 2111,
    distressRanking:8,
    product: "Node - Crypto iOS UI design kit",
    category: "UI design kit",
    image: "/images/content/booking3.png",
    image2x: "/images/content/product-pic-3@2x.jpg",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce maximus quam non neque efficitur tincidunt. Nullam sit amet malesuada dolor. Mauris vestibulum vehicula orci, ac feugiat risus pellentesque dapibus. Vestibulum ligula lorem, tincidunt at nisi sed, tincidunt vestibulum ex. Donec scelerisque justo sed nulla rhoncus convallis. Nunc elit neque, hendrerit sed urna eu, dictum suscipit ipsum. Vivamus non gravida risus, vitae congue velit. Vivamus congue blandit porttitor.",
    remark: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce maximus quam non neque efficitur tincidunt. Nullam sit amet malesuada dolor. Mauris vestibulum vehicula orci, ac feugiat risus pellentesque dapibus. Vestibulum ligula lorem, tincidunt at nisi sed, tincidunt vestibulum ex. Donec scelerisque justo sed nulla rhoncus convallis. Nunc elit neque, hendrerit sed urna eu, dictum suscipit ipsum. Vivamus non gravida risus, vitae congue velit. Vivamus congue blandit porttitor."
  },
  {
    id: 9,
    address:"8658 Laural Canyon Blvd",
    city:"Sun Valley",
    state:"CA",
    pincode:"91352",
    bed:"2",
    bath:"2",
    area:"900",
    contactPerson:"Jessica Nieto",
    phoneNumber:"562-714-2156",
    contactEmail:"jessica@booknieto.com",
    dom: 2,
    listPrice: 98,
    suggestedPrice: 3200,
    delta: 37.8,
    rehab: 79850,
    viewsPercent: progress(),
    likesPercent: progress(),
    arv: 2111,
    distressRanking:8,
    product: "TaskEz: Productivity App iOS UI Kit",
    category: "UI design kit",
    image: "/images/content/booking4.png",
    image2x: "/images/content/product-pic-4@2x.jpg",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce maximus quam non neque efficitur tincidunt. Nullam sit amet malesuada dolor. Mauris vestibulum vehicula orci, ac feugiat risus pellentesque dapibus. Vestibulum ligula lorem, tincidunt at nisi sed, tincidunt vestibulum ex. Donec scelerisque justo sed nulla rhoncus convallis. Nunc elit neque, hendrerit sed urna eu, dictum suscipit ipsum. Vivamus non gravida risus, vitae congue velit. Vivamus congue blandit porttitor.",
    remark: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce maximus quam non neque efficitur tincidunt. Nullam sit amet malesuada dolor. Mauris vestibulum vehicula orci, ac feugiat risus pellentesque dapibus. Vestibulum ligula lorem, tincidunt at nisi sed, tincidunt vestibulum ex. Donec scelerisque justo sed nulla rhoncus convallis. Nunc elit neque, hendrerit sed urna eu, dictum suscipit ipsum. Vivamus non gravida risus, vitae congue velit. Vivamus congue blandit porttitor."

  },
];

export const released = [
  {
    id: 0,
    product: "Bento Matte 3D Illustration",
    category: "UI design kit",
    image: "/images/content/product-pic-1.jpg",
    image2x: "/images/content/product-pic-1@2x.jpg",
    price: 98,
    status: true,
    ratingValue: 4.8,
    ratingCounter: 87,
    sales: 3200,
    balance: 55.8,
    views: 47850,
    viewsPercent: progress(),
  },
  {
    id: 1,
    product: "DSM - Geometry pattern",
    category: "UI design kit",
    image: "/images/content/product-pic-2.jpg",
    image2x: "/images/content/product-pic-2@2x.jpg",
    price: 123,
    status: false,
    ratingValue: 4.6,
    ratingCounter: 127,
    sales: 4900,
    balance: 35.8,
    views: 32500,
    viewsPercent: progress(),
  },
  {
    id: 2,
    product: "Node - Crypto iOS UI design kit",
    category: "UI design kit",
    image: "/images/content/product-pic-3.jpg",
    image2x: "/images/content/product-pic-3@2x.jpg",
    price: 0,
    status: true,
    sales: 5600,
    balance: -15.8,
    views: 55500,
    viewsPercent: progress(),
  },
  {
    id: 3,
    product: "TaskEz: Productivity App iOS UI Kit",
    category: "UI design kit",
    image: "/images/content/product-pic-4.jpg",
    image2x: "/images/content/product-pic-4@2x.jpg",
    price: 33,
    status: false,
    ratingValue: 4.9,
    ratingCounter: 7,
    sales: 1100,
    balance: 19.2,
    views: 39500,
    viewsPercent: progress(),
  },
  {
    id: 4,
    product: "Academe 3D Education Icons",
    category: "UI design kit",
    image: "/images/content/product-pic-5.jpg",
    image2x: "/images/content/product-pic-5@2x.jpg",
    price: 45,
    status: true,
    ratingValue: 4.8,
    ratingCounter: 87,
    sales: 3200,
    balance: 55.8,
    views: 47850,
    viewsPercent: progress(),
  },
];
