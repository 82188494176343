import axios from "axios";

const axiosService = axios.create({
    baseURL : "https://xjus0owbg6.execute-api.us-east-1.amazonaws.com/dev/",
})

axiosService.interceptors.request.use(
    (config) => {
      // Retrieve the access token from storage (adjust the key if needed)
      const accessToken = localStorage.getItem('access_token');
  
      // Add the access token to the Authorization header
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
  
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
export default axiosService;


