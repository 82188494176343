import React from "react";
import styles from "./ProductsDashboard.module.sass";
import TooltipGlodal from "../../components/TooltipGlodal";
import Overview from "./Overview";
import Products from "./Products";

const ProductsDashboard = () => {
    return (
        <>
            <div className={styles.section}>
                <Overview className={styles.card} />
                <Products />
            </div>
            <TooltipGlodal />
        </>
    );
};

export default ProductsDashboard;
