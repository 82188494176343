import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
// import history from "./utils/history";
import { getConfig } from "./config";

import App from "./App";


const onRedirectCallback = (appState) => {
  // history.push(
  //   appState && appState.returnTo ? appState.returnTo : window.location.pathname
  // );
};

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),
  },
};

ReactDOM.createRoot(document.getElementById("root")).render(
  // <React.StrictMode>
  <BrowserRouter>
    <Auth0Provider
      // domain={process.env.REACT_APP_AUTH0_DOMAIN}
      // clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      // authorizationParams={{
      //   redirect_uri: window.location.origin,
      // }}
      {...providerConfig}
    >
      <App />
    </Auth0Provider>
  </BrowserRouter>
  // </React.StrictMode>
);
